<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 17.5003C2 17.7762 2.22356 18 2.5 18H9H12H14.1707C14.0602 17.6872 14 17.3506 14 17V2.33333C14 2.14924 13.8508 2 13.6667 2H2.5C2.22386 2 2 2.22386 2 2.5V10.2286V17.5003ZM2.5 20H9H12H17C18.6569 20 20 18.6569 20 17V5.13861C20 4.50977 19.4902 4 18.8614 4L17 4H16V2.33333C16 1.04467 14.9553 0 13.6667 0H2.5C1.11929 0 0 1.11929 0 2.5V10.2286V17.5003C0 18.8813 1.11958 20 2.5 20ZM16 6V17C16 17.5523 16.4477 18 17 18C17.5523 18 18 17.5523 18 17V6H16.5L16 6ZM5 9C4.44772 9 4 9.44771 4 10C4 10.5523 4.44772 11 5 11H11C11.5523 11 12 10.5523 12 10C12 9.44771 11.5523 9 11 9H5ZM4 14C4 13.4477 4.44772 13 5 13H11C11.5523 13 12 13.4477 12 14C12 14.5523 11.5523 15 11 15H5C4.44772 15 4 14.5523 4 14ZM5 5C4.44772 5 4 5.44771 4 6C4 6.55228 4.44772 7 5 7H11C11.5523 7 12 6.55228 12 6C12 5.44771 11.5523 5 11 5H5Z" fill="black"/>
  </svg>
</template>

<script>
export default {
  name: "Document"
}
</script>

<style scoped>

</style>