<template>
  <div class="d-flex justify-content-between item">

    <div class="d-flex align-items-center"
         style="width: 40%">

      <b-form-checkbox class="item-selected" v-if="documentsAvailable"
                       v-on:change="selectDocumentHandler"
                       v-model="selected"/>
      <DocumentIcon/>

      <div class="orange-dot"
            v-if="!documentRead"></div>
      <span class="name" v-on:click="clickDocumentNameHandler(document)"
            :style="documentFileNameStyles">
        {{ document.file_name }} <span v-if="document.deprecated">(Deprecated)</span>
      </span>
    </div>

    <div class="flex flex-column">
      <div class="updated-caption">Updated</div>
      <div>{{ documentUpdated }}</div>
    </div>

    <div class="flex flex-column">
      <b-button class="btn btn-link" variant="primary" @click="downloadHandler" v-if="documentsAvailable">
          <DownloadIcon />
          Download
      </b-button>
    </div>

     <div class="flex flex-column">
        <div class="feedback" v-if="documentsAvailable" >
            <b-button class="btn btn-link" variant="primary" @click="sendFeedbackHandler" >
              <FeedbackIcon />
              Add a comment
            </b-button>
        </div>
      </div>

  </div>


</template>

<script>
import Download from "@/components/icons/Download";
import Document from "@/components/icons/Document";
import FeedbackIcon from "@/components/icons/FeedbackIcon";
import {DateTime} from 'luxon';
import {mapActions, mapGetters} from "vuex";
import router from "../../../routes";
import {email, maxLength, required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
  name: "Item",
  components: {
    DocumentIcon: Document,
    DownloadIcon: Download,
    FeedbackIcon
  },
  mixins: [validationMixin],
  props: {
    document: {
      type: Object
    },
    documentsAvailable: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      email: null,
      loading: false,
      selected: false,
    }
  },
  mounted() {
    this.selected = this.selectedDocumentIds.includes(this.document.id);
  },
  watch: {
      selectedDocumentIds() {
        this.selected = this.selectedDocumentIds.includes(this.document.id);
      }
  },
  computed: {
    ...mapGetters('auth/user', ['userInFinalClaimGroupRead', 'canReadFinalClaimPack']),
    ...mapGetters('auth/user/claim', ['isClaimPackApproved']),
    ...mapGetters('finalClaimPack', ['selectedDocumentIds', ]),
    documentUpdated() {
      return DateTime.fromISO(this.document.updated_at, {zone: process.env.VUE_APP_TIME_ZONE})
          .toFormat('dd.MM.yyyy HH:mm');
    },
    documentFileNameStyles() {
      return {
        color: (this.documentsAvailable) ? '#212721' : '#777777',
        'font-weight': (this.documentRead) ? 400 : 900,
      }
    },
    documentRead() {
      return this.document.read !== false;
    }
  },
  validations: {
    email: {
      required, email, maxLength: maxLength(255)
    },
  },
  methods: {
    ...mapActions('finalClaimPack', ['download', 'sendFeedback', 'updateSelection', ]),
    ...mapActions('notifications', ['addNotification']),
    sendFeedbackHandler() {
      router.push({
        name: 'preview',
        params: { type: 'claim-pack', id: this.document.id, },
        query: {action: 'messages', },
      });
    },
    selectDocumentHandler() {
      this.updateSelection({documentId: this.document.id, selectionState: this.selected})
    },
    downloadHandler() {
      this.download(this.document);
    },
    validateState(name) {
      const {$dirty, $error} = this.$v[name];
      return $dirty ? !$error : null;
    },
    clickDocumentNameHandler(document) {
      if (this.documentsAvailable)
        this.$router.push({ name: 'preview', params: { type: 'claim-pack', id: document.id } })
    }
  }
}
</script>

<style scoped>

</style>