<template>
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4939 7.97423C11.8056 7.69136 11.8372 7.19999 11.5644 6.87672C11.2917 6.55344 10.8178 6.52069 10.5061 6.80355L8.75 8.39707V0.777778C8.75 0.348223 8.41421 0 8 0C7.58579 0 7.25 0.348223 7.25 0.777778V8.39707L5.49388 6.80355C5.18215 6.52069 4.70833 6.55344 4.43557 6.87672C4.16281 7.19999 4.19439 7.69136 4.50612 7.97423L7.50612 10.6964C7.78889 10.953 8.21111 10.953 8.49388 10.6964L11.4939 7.97423ZM2 10.1111C2 9.68156 1.66421 9.33333 1.25 9.33333C0.835786 9.33333 0.5 9.68156 0.5 10.1111V12.4444C0.5 13.3036 1.17157 14 2 14H14C14.8284 14 15.5 13.3036 15.5 12.4444V10.1111C15.5 9.68156 15.1642 9.33333 14.75 9.33333C14.3358 9.33333 14 9.68156 14 10.1111V12.4444H2V10.1111Z" fill="#E83B2C"/>
  </svg>
</template>

<script>
export default {
  name: "Download"
}
</script>

<style scoped>

</style>