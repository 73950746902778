<template>
  <Workspace>
    <template v-slot:top-bar>
      <TopBar>
        <template v-slot:title>Final claim pack</template>
      </TopBar>
    </template>

    <template v-if="isClaimSelected && documents" v-slot:content>
      <div class="content flex-grow-1">
        <div class="claim-pack-wrap" :style="{maxHeight: height + 'px', height: height + 'px'}">
          <b-row class="my-3">
            <b-col lg="8" xl="9">
              <b-alert v-if="isClaimPackClosed" variant="info" show>
                If you want to contact us please contact us using the information found in the contact us section
              </b-alert>
              <b-alert v-if="isShowNotification" variant="danger" show>
                {{ final_sign_off_statuses[finalSignOffStatus] }}
              </b-alert>
              <b-alert v-if="isClaimPackApproved || isClaimPackMycoApprove" variant="info" show>

                <span v-if="isClaimPackApproved">
                  Final claim pack has been approved by {{finalClaimPackApprover.firstname}} {{finalClaimPackApprover.lastname}}
                  at {{finalSignOffPackApprovedDateFormatted}}
                </span>
                <span v-else>
                  The final claim pack for {{ selectedClaim.name }} is ready for your review
                </span>

              </b-alert>
            </b-col>

            <b-col lg="4" xl="3" class="text-right pl-0">
              <b-button v-if="canApproveFinalClaimPack && isClaimPackMycoApprove && !isClaimPackApproved"
                        :disabled="!isReadAllDocuments"
                        @click="approve" size="lg" variant="success">
                Approve all documents
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="8" lg="7" xl="5" class="my-2" >
              <div v-if="Object.keys(documents).length !== 0">
                <div class="filters d-flex justify-content-between align-items-center mt-1">
                  <div class="filter" :class="{active: filter.active}"
                      v-for="(filter, key) in filters"  :key="key"
                      @click="filterHandler(key)">
                    {{filter.title}}
                  </div>
                </div>
              </div>

            </b-col>
          </b-row>

          <div class="final-claim-pack my-3 pr-1">
           




        <div v-if=" shouldRenderCategories">
            <div v-for="(type, key) in types"
                 :key="type">
               
              <div v-if="visibleTypes.includes(type) && documents[key]">

                <div class="header">
                  <h2>{{ type }}</h2>
                </div>
                <div class="document-pack">
                  <div v-if="documents[key]">
                    <Item v-for="(document) in documents[key]"
                          :document="document"
                          :documentsAvailable="documentsAvailable"
                          :key="`${document.id}`"/>
                  </div>
                  <div class="empty-item" v-if="documents[key] === undefined">
                    <span>You don't have documents of this type yet</span>
                  </div>
                </div>

              </div>
              
              
              


            </div>
            </div>
            <div class="empty-item"  v-else>
              <div v-if="documents.length">
                You don't have documents yet
              </div>
              
            </div>

          </div>

          <b-row>

            <b-col sm="12" v-if="selectedDocumentIds.length > 0">
              <button class="btn btn-primary" @click="downloadSelectedHandler">Download selected</button>
            </b-col>

          </b-row>

        </div>

        <MsgBox ref="approveDocumentMsgBox" message="Are you sure you want to approve documents?"
                ok-title="Yes, approve" title="Confirm approving"/>
      </div>
    </template>

    <template v-else-if="isClaimSelected" v-slot:content>
      <b-row class="mt-4">
        <b-col ms="12">
          <b-alert v-if="hasDocuments === false" variant="danger" show>
            We are currently preparing your R&D claim, once complete you will be able to review and approve.
          </b-alert>
        </b-col>
      </b-row>
    </template>

  </Workspace>
</template>

<script>

import Workspace from "../layouts/Workspace";
import TopBar from "../layouts/workspace/TopBar";
import {mapActions, mapGetters} from "vuex";
import Item from "./parts/final_claim_pack/Item";
import types from "../types/claim_pack_groups";
import final_sign_off_statuses from "../types/final_sign_off_statuses"
import _ from "lodash";
import MsgBox from "../components/MsgBox";
import {DateTime} from "luxon";

export default {
  name: "FinalClaimPack",
  data() {
    return {
      types, final_sign_off_statuses,
      filters: {
        hmrc: {
          title: 'Documents for HMRC',
          active: true,
        },
        you: {
          title: 'Documents for you',
          active: false,
        },
      },
    }
  },
  components: {MsgBox, Item, Workspace, TopBar},
  computed: {
    ...mapGetters('finalClaimPack', ['documents', 'isReadAllDocuments', 'selectedDocumentIds', ]),
    ...mapGetters('auth/user/claim', [
        'selectedClaim',
        'isShowNotification',
        'isClaimPackApproved',
        'isClaimPackMycoApprove',
        'isClaimPackClosed',
    ]),
    ...mapGetters('auth/user', ['canApproveFinalClaimPack']),
    visibleTypes() {
      return (this.filters.hmrc.active) ? Object.values(this.types).filter(type => type !== 'Client Report' && type !== 'Your R&D Claim Explained' && type !== 'Numbers File') :
                                          Object.values(this.types).filter(type => type === 'Client Report'  || type === 'Your R&D Claim Explained' || type === 'Numbers File');
    },
    height() {
      return window.innerHeight - 100;
    },
    finalSignOffStatus: function () {
      return this.selectedClaim.final_sign_off_status.name;
    },
    isClaimSelected: function () {
      return !_.isEmpty(this.selectedClaim)
    },
    hasDocuments: function () {
      return !_.isEmpty(this.documents);
    },
    shouldRenderCategories() {
      const categoryKeys = Object.keys(this.types).filter((type) =>
        this.visibleTypes.includes(this.types[type])
      );
      return categoryKeys.some((key) => key in this.documents);
    },
    documentsAvailable: function() {
      return this.finalSignOffStatus === 'approval' || this.finalSignOffStatus === 'conditional_approval';
    },
    finalSignOffPackApprovedDateFormatted() {
      return (this.selectedClaim.final_sign_off_pack_approved) ? this.formatDate(this.selectedClaim.final_sign_off_pack_approved) : '';
    },
    finalSignOffStatusUpdatedAtFormatted() {
      return (this.selectedClaim.final_sign_off_status_updated_at) ? this.formatDate(this.selectedClaim.final_sign_off_status_updated_at) : '';
    },
    finalClaimPackApprover() {
        return (this.selectedClaim.final_sign_off_pack_approver) ?
                this.selectedClaim.final_sign_off_pack_approver : false;
    },
    finalSignOffStatusUpdater() {
      return (this.selectedClaim.final_sign_off_status_updater) ?
              this.selectedClaim.final_sign_off_status_updater : false;
    },

  },

  methods: {
    ...mapActions('finalClaimPack', ['fetchFinalClaimPack', 'downloadSeveralFiles', 'clearSelectedDocuments', ]),
    ...mapActions('auth/user/claim', ['finalClaimPackApprove', 'fetchClaim', ]),
    filterHandler(key) {
      for (let filter in this.filters) {
        this.filters[filter].active = false;
      }
      this.filters[key].active = true;
    },
    downloadSelectedHandler() {
      this.downloadSeveralFiles();
    },
    approve() {
      
      this.$refs.approveDocumentMsgBox.show()
          .then((value) => {
            if (value) {
              this.finalClaimPackApprove();
            }
          })
          .catch(() => {
          })
    },
    formatDate(date) {
      if (date)
        return DateTime.fromISO(date, {zone: process.env.VUE_APP_TIME_ZONE})
            .toFormat('dd.MM.yyyy HH:mm');
    },
  },
  watch: {
    selectedClaim() {
      if (this.isClaimSelected) {
        this.fetchFinalClaimPack();
      }
    }
  },
  mounted() {
    if (this.isClaimSelected) {
      this.fetchFinalClaimPack();
      this.fetchClaim(this.selectedClaim.id);

    }

  }
}
</script>

<style scoped>



</style>
